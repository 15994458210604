@use '@angular/material' as mat;

@use 'material/custom-styles/virtual-scroll-viewport' as virtual-scroll-viewport-custom-styles;

@use 'colors' as c;
@use 'variables' as v;
@use 'typography' as t;

@mixin mp-overview($theme) {
  $title-bar-background: c.$color-background-dialog;
  $card-active-background: c.$color-primary-50;
  $gray: #757575;

  $layout-color: c.$color-layout-500;

  .mp-overview {
    display: flex;
    min-height: v.$full;
    max-height: v.$full;

    .list-container {
      display: flex;
      flex-direction: column;
      flex: 1;

      .title-bar {
        display: flex;
        align-items: center;
        height: v.$default-toolbar-height;
        padding-left: v.$default-thickness * 2;
        background: $title-bar-background;

        @include mat.m2-typography-level(t.$mp-typography, headline-6);

        .title {
          display: flex;
          align-items: center;
          flex: 1;
          font-size: 20px;
          color: $layout-color;
          line-height: 24px;

          &__count {
            margin-left: 0.5 * v.$default-thickness;
            font-size: 16px;
            color: $gray;
            line-height: 14px;
          }
        }
      }

      .search-bar {
        padding: v.$default-thickness * 0.5 v.$default-thickness * 2 0;
      }

      .list-context {
        padding: v.$default-thickness v.$default-thickness 0;
        border-bottom: 1px solid c.$color-gray-100;

        &:empty {
          display: none;
        }
      }

      .list {
        display: flex;
        flex-direction: column;
        overflow: hidden;
        overflow-y: auto;
        flex: 1;

        .cdk-virtual-scroll-viewport {
          flex: 1;

          @include virtual-scroll-viewport-custom-styles.virtual-scroll-content-wrapper-display(flex);
          @include virtual-scroll-viewport-custom-styles.virtual-scroll-content-wrapper-flex-direction(column);
        }
      }
    }

    .detail-view-container {
      display: flex;
      flex: 2;
      box-shadow: v.$default-box-shadow;

      > :not(router-outlet) {
        width: 100%;
      }
    }

    .layout-full {
      flex: 1 0 v.$full;
      transform: translateX(0);
      transition: v.$container-transition;
      min-width: 0;
      min-height: 0;
    }

    .navigated {
      transform: translateX(-100%);
      transition: v.$container-transition;
    }

    &--custom {
      .detail-view-container {
        overflow-y: auto;
      }
    }
  }
}
