@mixin virtual-scroll-viewport-default-styles {
  cdk-virtual-scroll-viewport {
    .cdk-virtual-scroll-content-wrapper {
      display: var(--mp-virtual-scroll-content-wrapper-display, block);
      flex-direction: var(--mp-virtual-scroll-content-wrapper-flex-direction, row);
      width: var(--mp-virtual-scroll-content-wrapper-width, auto);
      max-width: var(--mp-virtual-scroll-content-wrapper-max-width, none);
      height: var(--mp-virtual-scroll-content-wrapper-height, auto);
      contain: var(--mp-virtual-scroll-content-wrapper-contain, content);
    }
  }
}
