@use 'colors' as c;

@import 'swiper/scss';
@import 'swiper/scss/scrollbar';

@mixin carousel-global {
  :root {
    --swiper-scrollbar-bottom: 0;
    --swiper-scrollbar-sides-offset: 0;
    --swiper-scrollbar-bg-color: #{c.$color-gray-50};
    --swiper-scrollbar-drag-bg-color: #{c.$color-gray-200};
    --swiper-scrollbar-size: 8px;
  }
}
