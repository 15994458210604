@use 'variables' as v;

@mixin expansion-panel-default-styles {
  mat-expansion-panel {
    .mat-expansion-panel-body {
      $default-padding: 0 1.5 * v.$default-thickness v.$default-thickness;

      padding: var(--mat-expansion-panel-body-padding, $default-padding);
    }

    $default-header-hover-color: var(--mat-expansion-header-hover-color, transparent);
    --mat-expansion-header-hover-state-layer-color: #{$default-header-hover-color};

    mat-expansion-panel-header {
      span.mat-content {
        flex-direction: var(--mat-expansion-panel-content-flex-direction, row);
        height: var(--mat-expansion-panel-content-height, unset);
        width: var(--mat-expansion-panel-content-width, unset);
        margin: var(--mat-expansion-panel-content-margin, 0);
        overflow: var(--mat-expansion-panel-content-overflow, hidden);

        &.mat-content-hide-toggle {
          margin: var(--mat-expansion-panel-content-margin, 0px 8px 0px 0px);
        }
      }

      .mat-expansion-indicator {
        align-self: var(--mat-expansion-header-indicator-align-self, unset);
      }
    }

    .mat-expansion-panel-header.mat-expanded:hover {
      background: $default-header-hover-color;
    }

    .mat-expansion-panel-header.mat-expanded:focus {
      background: var(--mat-expansion-header-focus-color, transparent);
    }
  }
}
