@mixin virtual-scroll-content-wrapper-display($display) {
  --mp-virtual-scroll-content-wrapper-display: #{$display};
}

@mixin virtual-scroll-content-wrapper-flex-direction($flex-direction) {
  --mp-virtual-scroll-content-wrapper-flex-direction: #{$flex-direction};
}

@mixin virtual-scroll-content-wrapper-max-width($max-width) {
  --mp-virtual-scroll-content-wrapper-max-width: #{$max-width};
}

@mixin virtual-scroll-content-wrapper-width($width) {
  --mp-virtual-scroll-content-wrapper-width: #{$width};
}

@mixin virtual-scroll-content-wrapper-height($height) {
  --mp-virtual-scroll-content-wrapper-height: #{$height};
}

@mixin virtual-scroll-content-wrapper-contain($contain) {
  --mp-virtual-scroll-content-wrapper-contain: #{$contain};
}
